/* You can add global styles to this file, and also import other style files */
@import "swiper/scss";
@import "swiper/scss/grid";
@import "tailwindcss/base";
@import "ngx-toastr/toastr";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "css-star-rating/scss/star-rating";

.ngx-toastr {
  width: 100% !important;
  max-width: 90% !important;
  @media (min-width: 1024px) {
    max-width: 50% !important;
  }
}

// angular-star-rating overrides
.rating {
  margin-bottom: 0 !important;
  .star-container {
    margin: 0 !important;
    .star {
      width: 13px !important;
      height: 13px !important;
    }
  }
}

.BeaconFabButtonFrame {
  bottom: 0px !important;
  @media (min-width: 1024px) {
    bottom: 100px !important;
  }
}
.BeaconContainer {
  transform: translateY(-50px) !important;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
